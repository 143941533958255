import React, {Fragment} from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { stylesMaterial } from "../../styles/StylesMaterialUI";
import "../../styles/ResultStyles.css";

export default class ResultForm extends React.Component {
  BASE_URL = process.env.REACT_APP_BASE_URL;
  CALL_ACTION = process.env.REACT_APP_CALL_ACTION_RESULT_DIAGNOSTIC;

  constructor(props) {
    super(props);
    this.state = {
      dataResult: "",
      dataDiagnostic: [],
      redirectToTesting: false,
    };
  }
  
  componentDidMount() {
    let diagnosticData = JSON.parse(localStorage.getItem("diagnostic"));
    let userData = JSON.parse(localStorage.getItem("user"));
    axios.get(`${this.BASE_URL}/result/${userData['id']}/${diagnosticData['id']}`)
    .then(resResult => {
      this.setState({ dataDiagnostic: JSON.parse(localStorage.getItem("diagnostic"))});
      this.setState({ dataResult: resResult.data});
    })
  }

  formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  readingSpeed = () => {
    let timeDiagnostic = localStorage.getItem("time");
    let wordsDiagnostic = this.state.dataDiagnostic['number_word'];
    return Math.floor((wordsDiagnostic/timeDiagnostic)*60);
  }

  render() {
    return (
      <Fragment>
        <Grid className="containerResult" container spacing={2}>
          <Grid item>
            <p className='resultTitle'>Diagnóstico Sinapsis S.R.L de Lectura y Comprensión</p>
            <p className='resultDescription'>Estudio PIRLS sobre la condición de lectura y comprensión en Europa refleja: Estudiantes de primaria leen 300, bachiller y universitario 400 y un profesional 500 palabras por minuto y todos con un <strong>80% de comprensión.</strong></p>
            <p className='resultDescription'>En América latina, existe CERLAC, fundado por la UNESCO, quienes determinaron que el 92% de los latinoamericanos manejan una velocidad de lectura entre 150 y 250 palabras por minuto con una comprensión entre el 40 y 60%.</p>
          </Grid>
          <Grid item xs={12} className="containerResultTesting">
            <p className='resultTitleTesting'>Condición actual de lectura y comprensión</p>
            <p className='resultDescriptionTesting'>Su nivel de comprensión es del <strong className='resultDescriptionTestingTotal'>{this.state.dataResult}</strong></p>
            <p className='resultDescriptionTesting'>Has leído <strong>{this.state.dataDiagnostic['number_word']}</strong> palabras en: <strong>{this.formatTime(localStorage.getItem("time"))}</strong></p>
            <p className='resultDescriptionTesting'>Su velocidad de lectura ha sido de: <strong>{this.readingSpeed()}</strong></p>
          </Grid>
          <Grid item xs={9} className="containerResultInformation">
            <p className='resultInformation'>Anticípese y agende un espacio con nuestro equipo especializado.</p>
          </Grid>
          <Button variant="contained" href={this.CALL_ACTION} target="_blank" style={stylesMaterial.buttonResultForm}>¡Contáctese aquí¡</Button>
        </Grid>
      </Fragment>
    )
  }
}
